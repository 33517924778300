export const characterDefs = {
  a:
    'M213.6,391.35a5.76,5.76,0,0,1-1.8-4.2l.6-3.3,75.9-194.7c1.59-4,4.5-6,8.7-6h10.2c4.2,0,7.09,2,8.7,6l75.9,194.7.6,3.3a6.15,6.15,0,0,1-6,6h-6a6.2,6.2,0,0,1-4.35-1.5,8.19,8.19,0,0,1-2.25-3l-18.6-48H249l-18.6,48a8.66,8.66,0,0,1-2.25,3,6.23,6.23,0,0,1-4.35,1.5h-6A5.74,5.74,0,0,1,213.6,391.35Zm42.6-68.7H348l-45.9-118.2Z',
  b: 'M230.1,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95h78.3q29.7,0,45,15.3t15.3,38.4q0,17.7-7.8,29.1t-18.6,16.5q12.88,6.6,21.75,20.1t8.85,31.2A60.88,60.88,0,0,1,370.35,363a55.83,55.83,0,0,1-21.45,21.9q-14.11,8.25-33.6,8.25H234.9A6.47,6.47,0,0,1,230.1,391.35Zm79.2-117q22.18,0,33.75-9.75t11.55-27.75q0-17.7-11.55-26.7t-33.75-9H247.2v73.2Zm3,100.8q21.88,0,34.2-11.55t12.3-29.85q0-18.6-12.3-30t-34.2-11.4H247.2v82.8Z',
  c: 'M241.2,374.1q-19.51-22.05-21-59.25-.3-8.1-.3-26.4,0-18.6.3-27,1.5-37.2,21-59.25t58.5-22q26.7,0,44.4,9.75t26.25,24.6A68.41,68.41,0,0,1,379.8,245v.6a4.84,4.84,0,0,1-1.8,3.9,6.79,6.79,0,0,1-4.5,1.5h-6c-3.6,0-5.81-2.1-6.6-6.3q-5.7-25.5-20.7-36t-40.5-10.5q-28.2,0-43.8,15.9T239.1,263q-.3,8.1-.3,24.9t.3,25.5q1.18,33,16.8,48.9t43.8,15.9q25.49,0,40.5-10.5t20.7-36c.79-4.2,3-6.3,6.6-6.3h6a7,7,0,0,1,4.65,1.65,4.7,4.7,0,0,1,1.65,4.35,68.41,68.41,0,0,1-9.45,30.45q-8.55,14.85-26.25,24.6t-44.4,9.75Q260.7,396.15,241.2,374.1Z',
  d:
    'M199.25,438.08a8.64,8.64,0,0,1-2.41-6.42V168.74a9.37,9.37,0,0,1,2.41-6.62,8.3,8.3,0,0,1,6.42-2.61h85.5q41.74,0,66,12.24t34.52,35.93q10.25,23.69,11,60.21.39,12,.4,31.71,0,20.07-.4,32.11-.81,38.54-11,62T357.8,428.85q-23.68,11.64-64.62,11.64H205.67A8.64,8.64,0,0,1,199.25,438.08Zm91.92-21.67q32.1,0,50.58-8.63a52.69,52.69,0,0,0,26.69-27.3q8.22-18.66,9-50.78.39-12,.4-30.1,0-17.66-.4-29.71-.81-44.94-21.07-65.63t-67.24-20.67h-67V416.41Z',
  e:
    'M239.25,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95h117a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8H256.35v76.5h98.1a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-98.1v79.5h107.1a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8H244.05A6.47,6.47,0,0,1,239.25,391.35Z',
  f:
    'M245,391.35a6.45,6.45,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.79-1.95H365.55a6.27,6.27,0,0,1,6.9,6.9v4.5a6.23,6.23,0,0,1-2,4.8,7,7,0,0,1-4.94,1.8H262.05v80.7h97.5a6.27,6.27,0,0,1,6.9,6.9v4.5a6.23,6.23,0,0,1-2,4.8,7,7,0,0,1-4.94,1.8h-97.5v86.7a6.25,6.25,0,0,1-1.95,4.8,7,7,0,0,1-5,1.8h-5.41A6.46,6.46,0,0,1,245,391.35Z',
  g:
    'M240.45,374.55q-20.7-21.6-21.9-57.9-.3-9-.3-28.5t.3-28.5q1.19-36.3,21.9-57.9t58.2-21.6q25.49,0,42.9,9.6T367.5,212.4q8.55,13,9.15,23.25v.6a4.84,4.84,0,0,1-1.8,3.9,6.79,6.79,0,0,1-4.5,1.5h-6.6a5.34,5.34,0,0,1-3.9-1.2,12.87,12.87,0,0,1-2.4-4.8q-5.11-15.9-19.65-26.7t-39.15-10.8q-27.9,0-43.95,15.3t-17.25,47.7q-.3,9-.3,27t.3,27q1.19,32.4,17.25,47.7t43.95,15.3q27.9,0,45-15.9t17.1-47.1v-15.3h-52.2a6.22,6.22,0,0,1-4.8-2A7,7,0,0,1,302,293v-4.2a7,7,0,0,1,1.8-4.95,6.22,6.22,0,0,1,4.8-1.95h64.2a6.27,6.27,0,0,1,6.9,6.9v26.4q0,24-9.6,42.3a68.53,68.53,0,0,1-27.9,28.5q-18.3,10.2-43.5,10.2Q261.14,396.15,240.45,374.55Z',
  h:
    'M223.05,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95h5.4a6.27,6.27,0,0,1,6.9,6.9v87.6H356v-87.6a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95H368a6.27,6.27,0,0,1,6.9,6.9v196.5a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4a6.19,6.19,0,0,1-6.6-6.6v-90.9H240.15v90.9a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4A6.47,6.47,0,0,1,223.05,391.35Z',
  i:
    'M290.4,391.35a6.49,6.49,0,0,1-1.8-4.8V189.75a6.19,6.19,0,0,1,6.6-6.6h5.7a6.19,6.19,0,0,1,6.6,6.6v196.8a6.19,6.19,0,0,1-6.6,6.6h-5.7A6.49,6.49,0,0,1,290.4,391.35Z',
  j:
    'M240.3,380.7Q220.05,365.25,218.85,335a6.78,6.78,0,0,1,1.65-4.8,6,6,0,0,1,4.65-1.8h6a6,6,0,0,1,4.65,1.8,7.85,7.85,0,0,1,1.95,4.5q1.8,22.2,16.65,32.85t37.35,10.65q25.78,0,39.75-16.95t13.95-44.85V201.15H234.75a6.19,6.19,0,0,1-6.6-6.6v-4.8a6.19,6.19,0,0,1,6.6-6.6h122.7a6.27,6.27,0,0,1,6.9,6.9v126.6q0,36-19.05,57.75t-53.55,21.75Q260.53,396.15,240.3,380.7Z',
  k:
    'M243.6,391.35a6.45,6.45,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.79-1.95h5.41a6.28,6.28,0,0,1,6.9,6.9v78l90.9-80.4a36.2,36.2,0,0,1,4.95-3.15,13.63,13.63,0,0,1,6.44-1.35h4.81a6.17,6.17,0,0,1,6,6,5.32,5.32,0,0,1-1.5,3.9l-101.7,90.6,108,99.3a6,6,0,0,1-4.21,10.2H369.3a13.65,13.65,0,0,1-6.45-1.35,36.91,36.91,0,0,1-5-3.15l-97.19-88.8v86.7a6.23,6.23,0,0,1-2,4.8,7,7,0,0,1-4.94,1.8h-5.41A6.46,6.46,0,0,1,243.6,391.35Z',
  l:
    'M249.9,391.35a6.49,6.49,0,0,1-1.8-4.8V189.75a6.19,6.19,0,0,1,6.6-6.6h5.7a6.19,6.19,0,0,1,6.6,6.6v185.4H370.5a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8H254.7A6.47,6.47,0,0,1,249.9,391.35Z',
  m:
    'M211.2,391.35a6.18,6.18,0,0,1-1.95-4.8V190.05a6.56,6.56,0,0,1,1.95-5.1,7,7,0,0,1,5-1.8h7.5a6.39,6.39,0,0,1,6.3,3.9l68.1,132.6,68.4-132.6a6.74,6.74,0,0,1,6.3-3.9H380a6.27,6.27,0,0,1,6.9,6.9v196.5a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4a6.19,6.19,0,0,1-6.6-6.6V224l-57.3,112.8a9.46,9.46,0,0,1-9.3,6h-6.6a9.44,9.44,0,0,1-9.3-6L228.15,224v162.6a6.19,6.19,0,0,1-6.6,6.6h-5.4A7,7,0,0,1,211.2,391.35Z',
  n:
    'M225.45,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.22,6.22,0,0,1,4.8-1.95h6.3a7.3,7.3,0,0,1,6.3,3.6L353.55,356V190.05a7,7,0,0,1,1.8-5,6.22,6.22,0,0,1,4.8-1.95h5.4a6.27,6.27,0,0,1,6.9,6.9v196.2a7,7,0,0,1-1.8,4.95,6.23,6.23,0,0,1-4.8,1.95h-6.6a7.28,7.28,0,0,1-6.3-3.6L242.55,221v165.6a6.22,6.22,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4A6.49,6.49,0,0,1,225.45,391.35Z',
  o:
    'M218.85,313.35q-.3-9-.3-25.2t.3-25.2q1.18-40.8,22.2-61.8t57-21q36,0,57,21t22.2,61.8q.6,18,.6,25.2t-.6,25.2q-2.1,82.8-79.2,82.8T218.85,313.35ZM341.1,361.8q16-16.35,17.25-49.95.6-18,.6-23.7t-.6-23.7q-1.2-33.6-17.4-49.95t-42.9-16.35q-26.71,0-42.9,16.35t-17.4,49.95q-.3,9-.3,23.7t.3,23.7q1.19,33.6,17.25,49.95t43.05,16.35Q325.05,378.15,341.1,361.8Z',
  p:
    'M237.3,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95H315q32.08,0,50.55,16T384,245.25q0,30-18.45,46.05T315,307.35H254.4v79.2a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4A6.47,6.47,0,0,1,237.3,391.35Zm76.2-102q25.78,0,38.7-11.25t12.9-32.85q0-21.6-12.9-32.85t-38.7-11.25H254.4v88.2Z',
  q:
    'M353.25,405.75l-13.2-18.3q-18,8.7-42,8.7-77.12,0-79.2-82.8-.3-9-.3-25.2t.3-25.2q1.18-40.8,22.2-61.8t57-21q36,0,57,21t22.2,61.8q.6,18,.6,25.2t-.6,25.2q-1.51,42.9-21.9,63l19.2,27a7.43,7.43,0,0,1,.9,3.3,6.15,6.15,0,0,1-6,6h-6.6Q358.35,412.65,353.25,405.75ZM341.1,361.8q16-16.35,17.25-49.95.6-18,.6-23.7t-.6-23.7q-1.2-33.6-17.4-49.95t-42.9-16.35q-26.71,0-42.9,16.35t-17.4,49.95q-.3,9-.3,23.7t.3,23.7q1.19,33.6,17.25,49.95t43.05,16.35Q325.05,378.15,341.1,361.8Z',
  r:
    'M232.05,391.35a6.49,6.49,0,0,1-1.8-4.8V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95h69.9q32.4,0,50.7,15.3t18.3,45.3q0,23.7-11.7,38.25t-33.6,19.65l48.3,82.2a7.47,7.47,0,0,1,.9,3.3,6.15,6.15,0,0,1-6,6h-3.6q-4.5,0-6.75-1.95a23.6,23.6,0,0,1-4.65-6.15L311,304.35h-61.8v82.2a6.25,6.25,0,0,1-2,4.8,7,7,0,0,1-4.95,1.8h-5.4A6.47,6.47,0,0,1,232.05,391.35Zm73.2-105q26.1,0,38.85-10.65t12.75-31.95q0-21.3-12.75-31.95t-38.85-10.65h-56.1v85.2Z',
  s:
    'M257.85,388.5q-17.27-7.65-26.4-19.95a46.73,46.73,0,0,1-9.75-26.4,5.76,5.76,0,0,1,1.8-4.2,6.11,6.11,0,0,1,4.5-1.8h6c3.6,0,5.79,2,6.6,6q2.09,14.4,16.5,25.2t41.4,10.8q28.49,0,42.45-10.35t13.95-28.95q0-11.4-6.75-18.75T328.5,307.2q-12.92-5.55-39-13.95-23.12-7.5-36.15-14.7T234,260.85q-6.3-10.5-6.3-26.7a48.36,48.36,0,0,1,8.25-27.6q8.24-12.3,23.85-19.35t37.2-7q22.19,0,38.1,7.95t24,20.1q8.1,12.15,8.7,24.45a5.91,5.91,0,0,1-6.3,6h-6a7.67,7.67,0,0,1-4.2-1.35,5.71,5.71,0,0,1-2.4-4.65q-1.8-15-16-24.75T297,198.15q-23.12,0-36.75,9t-13.65,27q0,11.7,6.15,19.35t18.45,13.2q12.29,5.55,36.3,13.35,24.58,7.8,38.4,15t20.85,17.4q7,10.2,7.05,26.4,0,27-20.1,42.15t-55.2,15.15Q275.1,396.15,257.85,388.5Z',
  t:
    'M294.29,391.35a6.49,6.49,0,0,1-1.8-4.8V201.15h-59.4a6.19,6.19,0,0,1-6.6-6.6v-4.5a7,7,0,0,1,1.8-5,6.22,6.22,0,0,1,4.8-1.95h137.4a6.27,6.27,0,0,1,6.9,6.9v4.5a6.22,6.22,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8h-59.1v185.4a6.22,6.22,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8h-5.4A6.49,6.49,0,0,1,294.29,391.35Z',
  u:
    'M240.75,375.3q-20-20.85-19.95-63.45V190.05a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95h5.4a6.27,6.27,0,0,1,6.9,6.9v122.7q0,33,15.3,49.2t43.2,16.2q27.58,0,42.9-16.2t15.3-49.2V190.05a6.56,6.56,0,0,1,2-5.1,7,7,0,0,1,4.95-1.8h5.4a6.23,6.23,0,0,1,4.8,1.95,7,7,0,0,1,1.8,5v121.8q0,42.9-19.8,63.6t-57.3,20.7Q260.69,396.15,240.75,375.3Z',
  v:
    'M287.85,386l-69.6-193.5-.6-3.3a6.15,6.15,0,0,1,6-6H230a6.22,6.22,0,0,1,4.35,1.5,8.66,8.66,0,0,1,2.25,3l65.7,183.6L368,187.65a8.42,8.42,0,0,1,2.25-3,6.2,6.2,0,0,1,4.35-1.5h6.3a6.15,6.15,0,0,1,6,6l-.6,3.3L316.65,386c-1.8,4.8-5.21,7.2-10.2,7.2h-8.4Q290.55,393.15,287.85,386Z',
  w:
    'M241.79,391.5q-1.95-1.65-2.85-5.55l-41.1-193.2-.6-3.6a6.15,6.15,0,0,1,6-6h6.3q5.7,0,6.6,4.5l35.1,171,37.2-122.1q2.4-6.9,9.3-6.9h6.6q6.9,0,9.3,6.9l37.2,122.1,35.1-171q.9-4.5,6.6-4.5h6.3a6.15,6.15,0,0,1,6,6l-.6,3.6L363.14,386q-.9,3.9-2.85,5.55a8.36,8.36,0,0,1-5.55,1.65h-4.5q-6.9,0-9.3-7.2L301,259.35,261.14,386q-2.1,7.2-9.3,7.2h-4.5A8.36,8.36,0,0,1,241.79,391.5Z',
  x:
    'M217.5,391.35a5.76,5.76,0,0,1-1.8-4.2,6.89,6.89,0,0,1,1.2-3.9l69.9-96.9-67.2-93.3a6.89,6.89,0,0,1-1.2-3.9,6.15,6.15,0,0,1,6-6h7.2q3.89,0,6.9,4.5l60.3,84,59.1-84q3.3-4.5,6.9-4.5h6.6a6.15,6.15,0,0,1,6,6,7,7,0,0,1-1.2,3.9L309.9,287l69.6,96.3a7,7,0,0,1,1.2,3.9,6.15,6.15,0,0,1-6,6h-7.2q-3.6,0-6.9-4.5L297.9,302l-62.7,86.7c-2,3-4.31,4.5-6.9,4.5h-6.6A5.74,5.74,0,0,1,217.5,391.35Z',
  y:
    'M294.75,391.35a6.49,6.49,0,0,1-1.8-4.8v-73.5l-73.2-120a9.34,9.34,0,0,1-1.2-3.9,6.15,6.15,0,0,1,6-6h6q4.2,0,6.9,4.5l65.1,105.9,65.1-105.9c1.8-3,4.09-4.5,6.9-4.5h6a6.15,6.15,0,0,1,6,6,9.49,9.49,0,0,1-1.2,3.9l-73.2,120v73.5a6.22,6.22,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8h-5.7A6.49,6.49,0,0,1,294.75,391.35Z',
  z:
    'M225.6,391.2a7,7,0,0,1-1.8-4.95v-6a13.82,13.82,0,0,1,2.1-8.1q2.08-3,2.4-3.3l118.2-167.7H233.1a6.19,6.19,0,0,1-6.6-6.6v-4.5a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95H362.4a6.27,6.27,0,0,1,6.9,6.9v6a13.24,13.24,0,0,1-1.05,5.55c-.71,1.5-1.65,3.25-2.85,5.25L247.8,375.15H365.4a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-1.95,4.8,7,7,0,0,1-5,1.8h-135A6.2,6.2,0,0,1,225.6,391.2Z',
  0:
    'M243.6,373.35q-16.65-22.8-17.55-60l-.3-24.9.3-25.5q.9-37.2,17.55-60t54.45-22.8q37.8,0,54.45,22.8t17.55,60q.29,8.4.3,25.5,0,16.8-.3,24.9-.9,37.2-17.55,60t-54.45,22.8Q260.25,396.15,243.6,373.35Zm93.6-12.45q13.33-17.25,13.95-49,.28-8.7.3-24t-.3-23.4q-.61-31.2-13.95-48.75t-39.15-17.55q-25.82,0-39.15,17.55T245,264.45l-.3,23.4.3,24q.59,31.8,13.95,49t39.15,17.25Q323.84,378.15,337.2,360.9Z',
  1:
    'M316.35,391.35a6.49,6.49,0,0,1-1.8-4.8V207.75l-53.7,41.4a8.28,8.28,0,0,1-4.2,1.5,6.25,6.25,0,0,1-5.1-2.7l-3.3-4.2a7.13,7.13,0,0,1-1.5-4.2,6.27,6.27,0,0,1,2.7-5.1l63.9-49.2a13.27,13.27,0,0,1,7.8-2.1h5.7a6.19,6.19,0,0,1,6.6,6.6v196.8a6.19,6.19,0,0,1-6.6,6.6h-5.7A6.47,6.47,0,0,1,316.35,391.35Z',
  2:
    'M231.45,391.35a6.49,6.49,0,0,1-1.8-4.8v-4.5q0-6,7.5-12.6l77.7-77.7q16.48-15.3,22.2-25.8t5.7-24.9q0-20.1-11.55-31.5t-32.85-11.4q-20.12,0-32.1,11.7t-14.4,31.5a7.44,7.44,0,0,1-6.9,6.9h-6a6.26,6.26,0,0,1-4.65-1.65,5.47,5.47,0,0,1-1.65-4,70.94,70.94,0,0,1,7.8-29.1,60.09,60.09,0,0,1,21.9-23.85q14.68-9.45,36-9.45,32.08,0,47.7,17.4t15.6,43.5a67,67,0,0,1-7.8,32.4q-7.82,14.4-24.9,30.3l-70.2,71.4h100.5a6.27,6.27,0,0,1,6.9,6.9v4.5a6.25,6.25,0,0,1-1.95,4.8,7,7,0,0,1-4.95,1.8h-123A6.47,6.47,0,0,1,231.45,391.35Z',
  3:
    'M259.8,389q-16.05-7.2-24.75-18.9a44.81,44.81,0,0,1-9.3-25.2,5.17,5.17,0,0,1,1.8-4.2,6.79,6.79,0,0,1,4.5-1.5h4.2a9.26,9.26,0,0,1,5.1,1.35c1.39.9,2.4,2.75,3,5.55q4.2,17.7,19,24.9t34.05,7.2q24,0,38.4-11.4t14.4-33q0-41.4-49.8-41.4h-26.4a6.19,6.19,0,0,1-6.6-6.6v-4.5a12.93,12.93,0,0,1,2.7-7.2l61.8-72.9h-90.6a6.19,6.19,0,0,1-6.6-6.6v-4.5a7,7,0,0,1,1.8-5,6.22,6.22,0,0,1,4.8-1.95h111.9a6.27,6.27,0,0,1,6.9,6.9v4.5a9.31,9.31,0,0,1-2.4,5.7l-63.3,74.1h6q31.79,0,50.25,15t18.45,44.4q0,19.2-9.3,33.45a58.63,58.63,0,0,1-25.5,21.6q-16.2,7.35-36.9,7.35Q275.85,396.15,259.8,389Z',
  4:
    'M325.8,391.35a6.49,6.49,0,0,1-1.8-4.8v-47.4H225a6.19,6.19,0,0,1-6.6-6.6v-4.8a15.1,15.1,0,0,1,3.3-9l91.2-130.5a9.92,9.92,0,0,1,4.5-3.75,15.87,15.87,0,0,1,6.6-1.35h12.3a6.19,6.19,0,0,1,6.6,6.6v131.4h27.3a6.19,6.19,0,0,1,6.6,6.6v4.8a6.19,6.19,0,0,1-6.6,6.6H342.9v47.4a6.19,6.19,0,0,1-6.6,6.6h-5.7A6.49,6.49,0,0,1,325.8,391.35Zm-83.7-70.2H324V204.45Z',
  5:
    'M259.8,388.2q-15.47-7.95-23.7-20.55a52.43,52.43,0,0,1-8.85-27v-.6a4.58,4.58,0,0,1,1.65-3.75,6.64,6.64,0,0,1,4.35-1.35H239a7.83,7.83,0,0,1,4.8,1.35q1.8,1.35,2.7,5.55,4.5,18.3,18.3,27.3a56.33,56.33,0,0,0,31.5,9q23.1,0,38.25-13.8T349.65,326q0-15-7-26.1a46.59,46.59,0,0,0-19.35-17.1,60.81,60.81,0,0,0-27-6q-12,0-19.35,3.15a77.81,77.81,0,0,0-14.85,8.55,62.62,62.62,0,0,1-7.35,4.8,13,13,0,0,1-6.15,1.5h-5.4a6.84,6.84,0,0,1-4.8-1.95,5.27,5.27,0,0,1-1.8-4.65l9-98.1a8.4,8.4,0,0,1,1.95-5.1,6,6,0,0,1,4.65-1.8H350a6.19,6.19,0,0,1,6.6,6.6v4.8a6.19,6.19,0,0,1-6.6,6.6h-86.1l-6.3,67.8q15.9-10.2,38.7-10.2,20.4,0,36.75,7.8A61.45,61.45,0,0,1,359,289.5q9.6,15.15,9.6,36.45,0,22.5-9.6,38.4A61,61,0,0,1,333,388.2q-16.37,7.94-36.75,7.95Q275.25,396.15,259.8,388.2Z',
  6:
    'M260.55,386.25a64.92,64.92,0,0,1-24.9-26.1,74.5,74.5,0,0,1-8.4-34.8q0-26.1,18.9-53.1l59.1-83.1a15.25,15.25,0,0,1,1.65-2.25,10.75,10.75,0,0,1,3.3-2.7,9,9,0,0,1,4.35-1h5.7a5.42,5.42,0,0,1,4.35,1.95,7,7,0,0,1,1.65,4.65,8,8,0,0,1-1.5,3.9l-45,63.6a79.26,79.26,0,0,1,20.4-2.4q23.1,0,39.6,9.75a64.07,64.07,0,0,1,24.9,25.95,76.29,76.29,0,0,1,0,69.6,65.06,65.06,0,0,1-24.9,26.1q-16.5,9.9-39.6,9.9T260.55,386.25ZM326.85,372a48.68,48.68,0,0,0,19.8-18.15q7.49-12,7.5-28.5T346.65,297a49,49,0,0,0-19.8-18,61,61,0,0,0-53.4,0,48.88,48.88,0,0,0-19.8,18q-7.5,11.85-7.5,28.35t7.5,28.5A48.56,48.56,0,0,0,273.45,372a61,61,0,0,0,53.4,0Z',
  7:
    'M266.55,391.2a6.91,6.91,0,0,1-1.65-4.65l.6-2.7,77.1-182.7H240.3a6.19,6.19,0,0,1-6.6-6.6v-4.5a7,7,0,0,1,1.8-5,6.2,6.2,0,0,1,4.8-1.95H356.1a6.27,6.27,0,0,1,6.9,6.9v4.5a30.58,30.58,0,0,1-2.1,11.1l-75.9,180a22,22,0,0,1-3.15,5.55,5.78,5.78,0,0,1-4.65,1.95h-6.3A5.41,5.41,0,0,1,266.55,391.2Z',
  8:
    'M260.1,389a61.16,61.16,0,0,1-26.85-21.3q-9.9-14.1-9.9-33.6,0-18.6,9-32.1a59.76,59.76,0,0,1,24.9-21,50.22,50.22,0,0,1-19.5-18.45q-6.92-11.55-6.9-27.15,0-26.1,19.05-40.65t48.15-14.55q29.08,0,48.15,14.55t19.05,40.65q0,15.6-7.05,27.15A52.16,52.16,0,0,1,338.85,281a59.88,59.88,0,0,1,24.9,21q9,13.5,9,32.1,0,19.5-9.9,33.6A61.27,61.27,0,0,1,336,389q-17,7.2-37.95,7.2T260.1,389Zm77.7-22.8q16-12,16.05-32.1,0-19.8-15.9-31.8t-39.9-12q-24,0-39.9,12t-15.9,31.8q0,20.1,16.05,32.1t39.75,12Q321.73,378.15,337.8,366.15Zm-5.4-104.4q14-9.9,14-26.4,0-17.1-13.8-27.15t-34.5-10q-20.7,0-34.5,10t-13.8,27.15q0,17.1,13.8,27t34.5,9.9Q318.43,271.65,332.4,261.75Z',
  9:
    'M268.2,391.2a7,7,0,0,1-1.65-4.65,6.78,6.78,0,0,1,1.5-3.9l47.4-66.6a75.49,75.49,0,0,1-19.5,2.4q-22.8,0-38.85-9.9A64.27,64.27,0,0,1,233,282.75a72.5,72.5,0,0,1,0-66.6,65.29,65.29,0,0,1,24.15-26q16-10,38.85-10t39,10a65,65,0,0,1,24.3,26,72.42,72.42,0,0,1,8.1,33.3q0,15.6-5.1,27.6a128.14,128.14,0,0,1-12.6,22.8l-61.8,87.3a16.08,16.08,0,0,1-1.65,2.25,10.87,10.87,0,0,1-3.3,2.7,9.05,9.05,0,0,1-4.35,1h-6A5.41,5.41,0,0,1,268.2,391.2Zm53.85-97.05a48.92,48.92,0,0,0,19.2-18,49.9,49.9,0,0,0,7.2-26.7q0-15.3-7.2-27a48.92,48.92,0,0,0-19.2-18,57.21,57.21,0,0,0-52.2,0,47.74,47.74,0,0,0-19.05,18q-7.05,11.7-7.05,27t7.05,26.85a48.06,48.06,0,0,0,19.05,17.85,57.21,57.21,0,0,0,52.2,0Z',

};
